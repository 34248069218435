import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./styles.module.css";

import discount from "../../assets/img/discount.jpg";

const Section6 = () => {
    return (
        <div className={styles.section6} id={"sales"}>
            <Container>
                <Row>
                    <Col md={3} className={styles.discount_left}>
                        <div>
                            <p className={styles.title}>Наші акції</p>
                            <p className={styles.subtitle}>
                            
                            </p>
                            <p className={styles.text}>
                            Супер акції: знижки на грумінг, подарунки замовникам! Поспішайте скористатися пропозиціями для вашого улюбленця! 🎁🐾
                            </p>
                        </div>
                    </Col>
                    <Col lg={9} className={styles.discount_right}>
                        <p className={styles.title}>Наші акції</p>
                        <img src={discount} alt="discount" />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default Section6;
