import React from "react";
import styles from './styles.module.css';

const Service = ({image, title, text}) => {
    return(
        <>
            <div className={styles.image_wrap}>
                <img src={image} alt="service"/>
            </div>
            <p className={styles.title}>{title}</p>
            <p className={styles.text}>{text}</p>

        </>
    )
}
export default Service;