import React, {useState, useEffect} from "react";
import {Container, Row, Col} from "react-bootstrap";
import styles from "./styles.module.css";
import Price from "../../components/Price";
import {initialPrice} from "../../data/price";

import breed_select_img from "../../assets/img/non_breed.jpg";
import bubbles from "../../assets/img/bubbles.png";
import animals from "../../assets/img/animals.png";
import bg from "../../assets/img/price_bg.png";
import mobile_non_breed from "../../assets/img/mobile_non_breed.jpg";

const Section5 = ({enableModal}) => {
	const [currentPrice, setCurrentPrice] = useState(false);

	let breeds = [];
	for (let key in initialPrice) {
		breeds.push(key);
	}

	useEffect(() => {
		// setCurrentPrice(initialPrice[breeds[0]]);
	}, []);

	const handleChange = (e) => {
		setCurrentPrice(initialPrice[e.target.value]);
	};

		return (
			<div className={styles.section5} id={"price"}>
				<img className={styles.price_bg} src={bg} alt=""/>
				<img className={styles.bubbles} src={bubbles} alt=""/>
				<Container>
					<Row>
						<Col md={12} lg={4} className={styles.price_info}>
							<div>
								<p className={styles.title}>Наш прайс</p>
								<p className={styles.subtitle}>
								    тут буде підзаголовок
								</p>
								<label className={styles.label} htmlFor="breeds">
								    Виберіть породу:
								</label>
								<select
									onChange={handleChange}
									className={styles.select}
									name="breeds"
									id=""
								>
									<option
										hidden
										disabled
										selected
										value="select breed"
									>
										Виберіть породу
									</option>
									{breeds.map((el, ind) => {
										return (
											<option key={ind} value={el}>
												{initialPrice[el].title}
											</option>
										);
									})}
								</select>
								<div className={styles.wrap}>
									<Price price={currentPrice.price}/>
								</div>
								<a
									onClick={enableModal}
									href="#"
									className={styles.link}
								>
									Записатись!
								</a>
							</div>
						</Col>
						<Col md={12} lg={8} className={styles.breed_col}>
							<div className={styles.non_breed}>
								<img
									src={breed_select_img}
									className={styles.breed_select_img}
								/>
								<img
									src={mobile_non_breed}
									className={styles.mobile_non_breed}
								/>
								<img className={styles.animals} src={animals}/>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		);
	};
	export default Section5;
