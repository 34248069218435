import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./styles.module.css";
import sec1Right from "../../assets/img/sec1_right.png";
import sec1_mob_cats from "../../assets/img/sec1_mob_cats.png";

const Section1 = ({ enableModal }) => {
    return (
        <div className={styles.section1}>
            <Container>
                <Row>
                    <Col lg={9} className={styles.left_section}>
                        <p className={styles.subtitle}>
                            Зроби свого вихованця щасливим!
                        </p>
                        <h2 className={styles.title}>
                        Професійна стрижка домашнiх тварин
                        </h2>
                        <p className={styles.text}>
                        Комплексний догляд вiд{" "}
                            <span></span>
                        </p>
                        <p className={styles.price}>
                            500 <span>грн</span>
                        </p>
                        <button onClick={enableModal} className={styles.button}>
                            Записатися на стрижку
                        </button>
                        <img
                            src={sec1_mob_cats}
                            className={styles.mobile_image}
                            alt=""
                        />
                    </Col>
                    <img
                        className={styles.sec1_image}
                        src={sec1Right}
                        alt="animals"
                    />
                </Row>
            </Container>
        </div>
    );
};

export default Section1;
