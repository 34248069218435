import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./styles.module.css";

import service1Image from "../../assets/img/service1.jpg";
import service2Image from "../../assets/img/service2.jpg";
import service3Image from "../../assets/img/service3.jpg";
import service4Image from "../../assets/img/service4.jpg";
import Service from "../../components/Service";

const Section4 = () => {
    const services = [
        {
            title: "Стрижка",
            text:
                "Ласкаво просимо на стрижку, купання, тримінг тварин! Професійний догляд, безпечні засоби, затишна атмосфера. Кохання та комфорт для вашого улюбленця!",
        },
        {
            title: "Купання",
            text:
                "Ласкаво просимо на стрижку, купання, тримінг тварин! Професійний догляд, безпечні засоби, затишна атмосфера. Кохання та комфорт для вашого улюбленця!",
        },
        {
            title: "Тримінг",
            text:
                "Ласкаво просимо на стрижку, купання, тримінг тварин! Професійний догляд, безпечні засоби, затишна атмосфера. Кохання та комфорт для вашого улюбленця!",
        },
        {
            title: "Інші процедури",
            text:
                "Ласкаво просимо на стрижку, купання, тримінг тварин! Професійний догляд, безпечні засоби, затишна атмосфера. Кохання та комфорт для вашого улюбленця!",
        },
    ];

    return (
        <div className={styles.section4} id={"services"}>
            <Container>
                <Row>
                    <Col xs={12}>
                        <p className={styles.title}>Наши услуги</p>
                    </Col>
                    <Col md={6} lg={3}>
                        <Service
                            image={service1Image}
                            title={services[0].title}
                            text={services[0].text}
                        />
                    </Col>
                    <Col md={6} lg={3}>
                        <Service
                            image={service2Image}
                            title={services[1].title}
                            text={services[0].text}
                        />
                    </Col>
                    <Col md={6} lg={3}>
                        <Service
                            image={service3Image}
                            title={services[2].title}
                            text={services[0].text}
                        />
                    </Col>
                    <Col md={6} lg={3}>
                        <Service
                            image={service4Image}
                            title={services[3].title}
                            text={services[0].text}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Section4;
