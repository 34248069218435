import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./styles.module.css";
import Feed from "react-instagram-authless-feed";

const Section7 = () => {
    let link = document.getElementsByClassName("eapps-link")[0];

    // setTimeout(function () {
    //     if (link) {
    //         link.remove();
    //     }
    // });
    // link.remove();
    return (
        <div className={styles.section7} id={"works"}>
            <Container>
                <Row className={styles.sec7_wrap}>
                    <Col lg={9} className={styles.inst_wrap}>
                        <div class="elfsight-app-f90c83fe-6c01-41c6-a6e5-18415f1f9c1d elf_instagram_feed"></div>
                        {/*{window.innerWidth > 768 ? (*/}
                        {/*    <Feed userName="happy_groom_dp" className="Feed" classNameLoading="Loading" limit="12" />*/}
                        {/*) : (*/}
                        {/*    <Feed userName="happy_groom_dp" className="Feed" classNameLoading="Loading" limit="6" />*/}
                        {/*)}*/}
                    </Col>
                    <Col lg={3} className={styles.inst_info_wrap}>
                        <p className={styles.subtitle}>Наш Instgram</p>
                        <p className={styles.title}>@happy_groom_dp</p>
                        <a
                            target="_blank"
                            href="https://www.instagram.com/happy_groom_dp/"
                            className={styles.link}
                        >
                            Подивитись все
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default Section7;
