import React from 'react';
import styles from './styles.module.css';

const Menu = ({onClick, list}) => {
    return (
        <ul className={styles.menu}>
            {list.map((item, index) => {
                return <li key={index}><a onClick={onClick} href={item.link}>{item.label}</a></li>
            })}
        </ul>
    )
}
export default Menu;