import React from "react";
import styles from "./styles.module.css";

const InfoLine = ({ image, text, link, className }) => {
    return (
        <div className={styles.info_line + " " + className}>
            {link ? (
                <a href={link}>
                    <img src={image} alt="icon" />
                    <p>{text}</p>
                </a>
            ) : (
                <>
                    <img src={image} alt="icon" />
                    <p>{text}</p>
                </>
            )}
        </div>
    );
};
export default InfoLine;
