export const initialPrice = {
    york: {
        title: 'Йорк',
        price: [
            {
                title: 'Полный комплекс',
                price: '700 грн'
            },
        ]
    },
    maltipu: {
        title: 'Мальтипу',
        price: [
            {
                title: 'Полный комплекс',
                price: 'от 800 грн'
            },
        ]
    },
    shpitz: {
        title: 'Шпиц',
        price: [
            {
                title: 'Полный комплекс',
                price: '800 грн'
            },
        ]
    },
    bolonka: {
        title: 'Болонка',
        price: [
            {
                title: 'Полный комплекс',
                price: '800 грн'
            },
        ]
    },
    shitzu: {
        title: 'Шит-цу',
        price: [
            {
                title: 'Полный комплекс',
                price: 'от 800 грн'
            },
        ]
    },
    chihua: {
        title: 'Чихуа',
        price: [
            {
                title: 'Полный комплекс',
                price: '500 грн'
            },
        ]
    },
    cat: {
        title: 'Кот',
        price: [
            {
                title: 'Полный комплекс',
                price: '700 грн'
            },
        ]
    },
    pudel: {
        title: 'Пудель',
        price: [
            {
                title: 'Полный комплекс',
                price: 'от 800 грн'
            },
        ]
    },
    mops: {
        title: 'Мопс',
        price: [
            {
                title: 'Полный комплекс',
                price: 'от 600 грн'
            },
        ]
    },
    jackrassel: {
        title: 'Джек Рассел терьер',
        price: [
            {
                title: 'Полный комплекс',
                price: 'от 600 грн'
            },
        ]
    },
    lhaso: {
        title: 'Лхасо апсо',
        price: [
            {
                title: 'Полный комплекс',
                price: '800 грн'
            },
        ]
    },
    spotch: {
        title: 'Скотч терьер',
        price: [

            {
                title: 'Полный комплекс',
                price: 'от 800 грн'
            },
        ]
    },
    westhinend: {
        title: 'Вест хайненд вайт терьер',
        price: [
            {
                title: 'Полный комплекс',
                price: 'от 800 грн'
            },
        ]
    },
    spaniel: {
        title: 'Спаниель',
        price: [

            {
                title: 'Полный комплекс',
                price: 'от 800 грн'
            },
        ]
    },
    cvergshnautser: {
        title: 'Цверг Шнауцер',
        price: [
            {
                title: 'Полный комплекс',
                price: 'от 700 грн'
            },
        ]
    },
    gryphone: {
        title: 'Грифон',
        price: [
            {
                title: 'Полный комплекс',
                price: 'от 700 грн'
            },
        ]
    },
    corgie: {
        title: 'Корги пем брок',
        price: [
            {
                title: 'Полный комплекс',
                price: 'от 1000 грн'
            },
        ]
    },
    others: {
        title: 'Общие услуги',
        price: [
            {
                title: 'Чистка ушей ',
                price: '70 грн'
            },
            {
                title: 'Обработка глаз',
                price: '50 грн'
            },
            {
                title: 'Чистка паранальных желез',
                price: '100 грн'
            },
            {
                title: 'Вычес подшерстка ',
                price: '500 грн/час'
            },
            {
                title: 'Вычес колтунов ',
                price: '600 грн/час'
            },
        ]
    },

}