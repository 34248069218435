import React from "react";
import styles from './styles.module.css';

const Advantage = ({image, title, text}) => {
    return (
        <div className={styles.advantage}>
            <div className={styles.img_wrap}>
                <img src={image} alt="adv"/>
            </div>
            <p className={styles.title}>{title}</p>
            <p className={styles.text}>{text}</p>
        </div>
    )
}
export default Advantage;