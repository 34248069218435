import React, {useEffect, useState} from "react";
import styles from './styles.module.css';
import { Formik } from "formik";
import { object, string, number } from "yup";
import InputMask from 'react-input-mask';



const ContactForm = () => {

    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setSuccess(false);
    }, [])

    // let elements = document.getElementsByClassName(styles.module_phone);
    // for (var i = 0; i < elements.length; i++) {
    //     new IMask(elements[i], {
    //         mask: '+{3}(000)000-00-00',
    //     });
    // }



    const sendFeedback = (templateId, variables) => {
        window.emailjs.send(
            'service_mhcnvjy', templateId,
            variables
        ).then(res => {
           setSuccess(true);
        })
            // Handle errors here however you like, or use a React error boundary
            .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    }

    const submitForm = (name, phone) => {
        const templateId = 'template_n1khax7';

        sendFeedback(templateId, {name, phone})
    }


    return (
        <div className={styles.contact_form}>
            <p className={styles.subtitle}>Форма для связи и записи...</p>
            <p className={styles.title}>Остались вопросы?</p>
            <p className={styles.subsubtitle}>Мы свяжемся с вами в ближайшее время!</p>
            <Formik
                initialValues={{
                    name: '',
                    phone: '',
                }}
                validationSchema={object().shape({
                    name: string()
                        .min(1, "Поле не может быть пустым")
                        .required("Поле обязательное!"),
                    phone: string()
                        .min(19, "Поле не может быть пустым")
                        .required("Поле обязательное!"),

                })}
                onSubmit={(values) => submitForm(values.name, values.phone)}
            >
                {({
                      values,
                      errors,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset,
                      setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={styles.inputs_wrap}>
                            <div>
                                <label htmlFor="">Имя<span className={styles.required}>*</span></label>
                                <input
                                    className={errors.name && styles.error_input}
                                    name="name"
                                    placeholder="Введите Ваше имя..."
                                    value={values.name}
                                    onBlur={handleBlur}
                                    error={errors.name}
                                    onChange={handleChange}
                                />
                                {errors.name && (
                                    <p className={styles.error}>
                                        {errors.name}
                                    </p>
                                )}
                            </div>

                            <div>
                                <label htmlFor="">Номер телефона<span className={styles.required}>*</span></label>
                                <InputMask
                                    className={styles.module_phone}
                                    maskChar=''
                                    mask="+38 (999) 999 99 99"
                                    name="phone"
                                    placeholder="+38 (ХХХ) ХХХ ХХ ХХ"
                                    onBlur={handleBlur}
                                    value={values.phone}
                                    error={errors.phone}
                                    onChange={handleChange}
                                />
                                {errors.phone && (
                                    <p className={styles.error}>
                                        {errors.phone}
                                    </p>
                                )}
                            </div>

                        </div>

                        <div className={styles.buttons_wrap}>
                            <button
                                disabled={isSubmitting}
                                type="submit"
                            >
                                Отправить
                            </button>
                        </div>
                        {success && <p className={styles.success}>Ваше сообщение отправлено!</p>}
                    </form>
                )}
            </Formik>
        </div>
    )
}
export default ContactForm;