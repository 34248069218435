import './App.css';
import Header from "./containers/Header";
import Section1 from "./containers/Section1";
import Section2 from "./containers/Section2";
import Section3 from "./containers/Section3";
import Section4 from "./containers/Section4";
import Section5 from "./containers/Section5";
import Section6 from "./containers/Section6";
import Section7 from "./containers/Section7";
import Footer from "./containers/Footer";
import Sidebar from "./components/Sidebar";
import Modal from "./containers/Modal";
import {useState} from "react";

function App() {
    const [isModal, setIsModal] = useState(false);


    const enableModal = (e) => {
        e.preventDefault();
        setIsModal(true);
    }

    const disableModal = (e) => {
        e.preventDefault();
        setIsModal(false);
    }
  return (
    <div className="App">
        {isModal && <Modal disableModal={disableModal} />}
      <Header />
      <Section1 enableModal={enableModal} />
      <Section2 />
      <Section3 enableModal={enableModal} />
      <Section4 />
      <Section5 enableModal={enableModal} />
      <Section6 />
      <Section7 />
      <Footer enableModal={enableModal} />
      <Sidebar />
    </div>
  );
}

export default App;
