import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./styles.module.css";

import adv1Image from "../../assets/img/adv1.png";
import adv2Image from "../../assets/img/adv2.png";
import adv3Image from "../../assets/img/adv3.png";
import adv4Image from "../../assets/img/adv4.png";
import Advantage from "../../components/Advantage";

const Section2 = () => {
    const advantage1 = {
        image: adv1Image,
        title: "Професіоналізм",
        text:
            "Це вміння професійно доглядати за тваринами, забезпечуючи їх комфорт, здоров'я та красу з уважністю та досвідом..",
    };
    const advantage2 = {
        image: adv2Image,
        title: "Бездоганна якість",
        text:
            "Це надання послуг з унікальною дбайливістю, увагою до дрібниць та високим стандартам, що гарантує вигляд та самопочуття тварин на найвищому рівні",
    };
    const advantage3 = {
        image: adv3Image,
        title: "Затишок та комфорт",
        text:
            "Це створення сприятливої, релаксуючої атмосфери, де тварини почувають себе комфортно та безпечно, та створення довіри між грумером та твариною.",
    };
    const advantage4 = {
        image: adv4Image,
        title: "Зручне розташування",
        text:
            "Обране з метою забезпечити легкий доступ для клієнтів, комфортне розміщення тварин та забезпечити ефективне функціонування студії.",
    };

    return (
        <div className={styles.section2}>
            <Container>
                <Row>
                    <Col xs={6} lg={3}>
                        <Advantage
                            image={advantage1.image}
                            title={advantage1.title}
                            text={advantage1.text}
                        />
                    </Col>
                    <Col xs={6} lg={3}>
                        <Advantage
                            image={advantage2.image}
                            title={advantage2.title}
                            text={advantage2.text}
                        />
                    </Col>
                    <Col xs={6} lg={3}>
                        <Advantage
                            image={advantage3.image}
                            title={advantage3.title}
                            text={advantage3.text}
                        />
                    </Col>
                    <Col xs={6} lg={3}>
                        <Advantage
                            image={advantage4.image}
                            title={advantage4.title}
                            text={advantage4.text}
                        />
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default Section2;
