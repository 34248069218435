import React from "react";
import styles from "./styles.module.css";

import wp from "../../assets/img/sb_wp.png";
import tg from "../../assets/img/sb_tg.png";
import vb from "../../assets/img/sb_vb.png";

const Sidebar = () => {
    return (
        <div className={styles.sidebar}>
            <a target="_blank" href="https://wa.me/+380681769648">
                <img src={wp} alt="icon" />
            </a>
            <a target="_blank" href="tg://resolve?domain=zii_first_bot">
                <img src={tg} alt="icon" />
            </a>
            <a target="_blank" href="viber://chat?number=+380681769648">
                <img src={vb} alt="icon" />
            </a>
        </div>
    );
};

export default Sidebar;
