import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./styles.module.css";
import InfoLine from "../../components/InfoLine";

import place from "../../assets/img/place_icon.png";
import life from "../../assets/img/lifecell.png";
import vodafone from "../../assets/img/vodafone.png";
import kyivstar from "../../assets/img/kyivstar.png";
import mail from "../../assets/img/mail_icon.png";
import inst from "../../assets/img/isnt_icon.png";
import fb from "../../assets/img/fb_icon.png";
import wp from "../../assets/img/wp_icon.png";

const Footer = ({ enableModal }) => {
    return (
        <div className={styles.footer} id={"contacts"}>
            <Container>
                <Row>
                    <Col lg={6} className={styles.map_wrap}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2646.0298513682474!2d35.05493771566007!3d48.45595677924998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dbe2d76bd3f4e5%3A0xd06818859d34e31e!2z0YPQuy4g0KjQtdCy0YfQtdC90LrQviwgOSwg0JTQvdC40L_RgNC-LCDQlNC90LXQv9GA0L7Qv9C10YLRgNC-0LLRgdC60LDRjyDQvtCx0LvQsNGB0YLRjCwgNDkwMDA!5e0!3m2!1sru!2sua!4v1605012732865!5m2!1sru!2sua"
                            frameBorder="0"
                            aria-hidden="false"
                            tabIndex="0"
                        ></iframe>
                    </Col>
                    <Col lg={6}>
                        <div className={styles.footer_info_wrap}>
                            <div className={styles.footer_left_info}>
                                <p className={styles.title}>Адреса</p>
                                <InfoLine
                                    className={styles.address}
                                    image={place}
                                    text={
                                        "г. Днiпро, вул. Шевченко 9 (вхiд iз двору)"
                                    }
                                />
                                <p className={styles.title}>Ми в соц.сетях</p>
                                <div className={styles.socials}>
                                    <a
                                        target="_blank"
                                        href="https://www.instagram.com/happy_groom_dp/"
                                    >
                                        <img src={inst} alt="icon" />
                                    </a>
                                    <a target="_blank" href="#">
                                        <img src={fb} alt="icon" />
                                    </a>
                                    <a
                                        target="_blank"
                                        href="https://wa.me/+380681769648"
                                    >
                                        <img src={wp} alt="icon" />
                                    </a>
                                </div>
                            </div>
                            <div className={styles.footer_right_info}>
                                <p className={styles.title}>Контакти</p>
                                <InfoLine
                                    className={""}
                                    image={kyivstar}
                                    text={"+38 (068) 176 96 48"}
                                    link={"tel:+380681769648"}
                                />
                                <InfoLine
                                    className={""}
                                    image={life}
                                    text={"+38 (063) 376-59-29"}
                                    link={"tel:+380633765929"}
                                />
                                <InfoLine
                                    className={""}
                                    image={mail}
                                    text={"grooming@gmail.com"}
                                    link={"mail:grooming@gmail.com"}
                                />
                            </div>
                        </div>
                        <div className={styles.questions}>
                            <p>Залишились питання?</p>
                            <a onClick={enableModal} href="#">
                            Подзвонити мені!
                            </a>
                        </div>
                    </Col>
                    <Col className={styles.wg}>
                        <a href="#">
                            Розробка: <span>WG</span>-Studio
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default Footer;
