import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Menu from "../../components/Menu";
import { menuScroll } from "./helper";

import styles from "./styles.module.css";
import logo from "../../assets/img/logo.png";
import phoneIcon from "../../assets/img/phone_icon.svg";
import arrow_down from "../../assets/img/arrow_down.svg";
import mob_phone from "../../assets/img/mob_phone.svg";

const Header = () => {
    const list = [
        { label: "ПРО НАС", link: "#about" },
        { label: "ПОСЛУГИ", link: "#services" },
        { label: "Наш Instagram", link: "#works" },
        { label: "Прайс", link: "#price" },
        { label: "Акції", link: "#sales" },
        { label: "КОНТАКТИ", link: "#contacts" },
    ];

    useEffect(() => {
        let button = document.getElementById("button_menu");
        let menu_responsive = document.getElementById("menu_responsive");
        button.addEventListener("click", function () {
            this.classList.toggle(styles.active);
            menu_responsive.classList.toggle(styles.active);
        });

        document.addEventListener("mouseup", function (e) {
            menu_responsive.classList.forEach((el, index) => {
                if (el === styles.active) {
                    if (!menu_responsive.contains(e.target)) {
                        button.click();
                    }
                    let parent = e.target.parentElement;
                    parent.classList.forEach((el, index) => {
                        if (el === styles.button_menu) {
                            button.click();
                        }
                    });

                    e.target.classList.forEach((el, index) => {
                        if (el === styles.button_menu) {
                            button.click();
                        }
                    });
                }
            });
        });
    }, []);

    const handleClick = (e) => {
        e.preventDefault();
        menuScroll(e.target.attributes.href.value);
    };

    const mobClick = (e) => {
        e.preventDefault();
        menuScroll(e.target.attributes.href.value);
        document.getElementById("button_menu").click();
    };

    const scrollToTop = (e) => {
        e.preventDefault();
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div className={styles.header}>
            <Container>
                <Row>
                    <Col md={2} className={styles.logo_wrap}>
                        <a onClick={scrollToTop} href="">
                            <img src={logo} alt="logo" />
                        </a>
                    </Col>
                    <Col md={7} className={styles.desktop_menu_wrap}>
                        <Menu onClick={handleClick} list={list} />
                    </Col>
                    <Col md={3} className={styles.header_phone}>
                        <span>
                            <a href="tel:+380681769648">
                                <img
                                    className={styles.phone_icon}
                                    src={phoneIcon}
                                    alt=""
                                />{" "}
                                +38 (068) 176-96-48{" "}
                                <img
                                    className={styles.arrow_down}
                                    src={arrow_down}
                                    alt=""
                                />{" "}
                            </a>
                            <div className={styles.hidden_phones}>
                                <a href="tel:+380633765929">
                                    +38 (063) 376-59-29
                                </a>
                            </div>
                        </span>
                    </Col>

                    <Col xs={2} className={styles["mobile-toggle"]}>
                        <div id="button_menu" className={styles.button_menu}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </Col>
                    <Col xs={8} className={styles["mobile-logo"]}>
                        <a onClick={scrollToTop} href="">
                            <img src={logo} alt="logo" />
                        </a>
                    </Col>
                    <Col xs={2} className={styles.mobile_phone}>
                        <a href="tel:+380681769648">
                            <img src={mob_phone} alt="" />
                        </a>
                    </Col>
                </Row>

                <div id="menu_responsive" className={styles.menu_responsive}>
                    <nav className={styles.main_menu}>
                        <Menu onClick={mobClick} list={list} />
                    </nav>
                </div>
            </Container>
        </div>
    );
};

export default Header;
