import React from "react";
import {Container, Row, Col} from "react-bootstrap";
import styles from './styles.module.css';

import leftImage from '../../assets/img/sec3.jpg';

const Section3 = ({enableModal}) => {
    return(
        <div className={styles.section3} id={"about"}>
            <Container>
                <Row>
                    <Col md={6} className={styles.left}>
                        <img src={leftImage} alt="groom"/>
                    </Col>
                    <Col lg={6} className={styles.Right}>
                        <p className={styles.title}>Happy Groom</p>
                        <p className={styles.subtitle}>експерт з догляду за Вашим вихованцем</p>
                        <p className={styles.text}>Надаємо бездоганну якість грумінгу та гостинний затишок для вашого улюбленця. Професійний персонал, зручне розташування в центрі міста, індивідуальний підхід та любов до тварин - ось що нас відрізняє. Запрошуємо! 🐾
                        </p>
                        <a onClick={enableModal} href="#" className={styles.button}>Дізнатися більше</a>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Section3;