import React, {useEffect} from "react";
import styles from './styles.module.css';
import Portal from "../../components/Portal";
import ContactForm from "../../components/ContactForm";



import close from '../../assets/img/close.png';

const Modal = ({disableModal}) => {

    // const closeModal = ({ target }) => {
    //     if (this.modal && !this.modal.contains(target)) {
    //         this.props.disableModal();
    //     }
    // };
    //
    // useEffect(() => {
    //     document.addEventListener("click", closeModal, false);
    //     return () => {
    //         document.removeEventListener("click", closeModal, false);
    //
    //     }
    // }, [])



    return(
            <Portal >
                <div className={styles.modal_wrap}>
                    <a className={styles.close} onClick={disableModal} href="#"><img src={close} alt=""/></a>
                    <ContactForm />
                </div>
            </Portal>
    )
}
export default Modal;
