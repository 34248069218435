import React from "react";
import styles from './styles.module.css';

const Price = ({price}) => {

    return price ? (
        <div className={styles.price}>
            {price.map((item, index) => {
                if(index % 2) {
                    return <div key={index} className={styles.blue}>{item.title} <span>{item.price}</span></div>
                } else {
                    return <div key={index} className={styles.white}>{item.title} <span>{item.price}</span></div>
                }
            })}
        </div>
    ) : (<div>

    </div>)
}
export default Price;